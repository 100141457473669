%molecule-media-audio{
    .media-content{
        display: flex;
        flex-wrap: wrap;
    }

    .media-ratio{
        text-align: center;
        margin-top: 30px;

        audio{
            max-width: 100%;
        }

        .media-audio-player{
            @extend %molecule-audio-player;
        }
    }

}
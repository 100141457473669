%organism-page-list{
    
    // Grille
    .view-grid{
        @extend %molecule-grid-basic;  
    }
    
    // Pagination
    .pagination{
        @extend %molecule-pagination;
        margin-bottom: 40px;
    }
}
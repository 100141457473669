%text-basis-rte{    
    font-family: $site-font;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4em;
    color: #595959;
    letter-spacing: 0.05em;
    font-weight: 400;
}

%text-caption-rte{
    color: #000000;
    font-size: 13px;
    font-size: 1.3rem;
    font-weight: 300;
}

%text-teaser-description{
    font-weight: 400;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: normal;
}
.not-front{
    @extend %text-basis-rte;
    #loader{
        display: none;
    }
    header{
        .logo-texted{
            opacity: 1;
        }
    }
    .region-post-header{
        @extend %organism-post-header;
    }
    .region-nav-tools{
        @extend %organism-nav-tools;
    }

    .hat{
        @extend %atom-hat-default;
        @extend %bone-last-unmarged;
        margin-bottom: 30px;
    }

    #main-content > .container{ 
        @extend %container;
    }


    /*RTE*/
    .rte{
        @extend %organism-rte;
    }

    //  CrossReading
    .block-crossreading{
        @extend %molecule-slider--wambrechies-duo;
        margin-bottom: $margin-item-rte;
        clear: both;
        
        &.crossreading-auto{
            @extend %container;
            margin-bottom: $margin-item-rte;
            margin-bottom: 40px;

            .owl-nav{
                @include RWD(tablet){
                    width: calc(100% - 80px);
                }
                @include RWD(mobile){
                    width: calc(100% - 40px);
                }
            }
        }
    }

    /* Structure grilles */
    .grid{
        clear: both;
        @include RWD(desktop){
            @include grid-distribution(4, 20, 'grid-item');                
        }
        @include RWD(tablet){
            @include grid-distribution(2, 20, 'grid-item');                
        }
        @include RWD(mobile){
            @include grid-distribution(1, 0, 'grid-item');                
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color-access;
        font-size: 12px;
        font-size: 1.2rem;
    }

    /* FORM CLIENTS */
    @extend %organism-form;
    



    // Commentaires
    #comments{
        @extend %organism-rte; 
        @extend %container-form;
        clear: both;
        border-top: solid 1px $core-color;
        padding-top: 30px;
        padding-right: 170px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        form{
            @extend %molecule-form;
            @extend %bone-form-structure;
        }

        .comment-block-title{
            display: none;
        }

        .comment{
            display: flex;
            flex-wrap: wrap;
            max-width: 580px;
            padding: 20px 15px;
            border: solid 1px #d0d0d0;
            position: relative;
            & > * {
                width: 100%;
            }

            h3{
                order: 2;
            }
            .submitted{
                order: 0;
                color: #d0d0d0;
                font-size: 12px;
                font-size: 1.2rem;
                text-align: right;

                a{
                    display: none;
                }
            }
            .content{
                order: 5;
            }
            .links{
                order: 20;
            }
        }
    }

    // Comarquage
    #comarquage{
        @extend %organism-comarquage;
    }
    
}
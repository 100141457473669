%molecule-trombinoscope{
    clear: both;
    margin-bottom: 40px;

    @include RWD(tablet){
        margin-bottom: 20px;
    }
    
    @include RWD(desktop){
        @include grid-distribution(2, 40, 'grid-item'); 
    }
    @include RWD(tablet_only){
        @include grid-distribution(2, 20, 'grid-item');
    }
    
    .grid-item{
        @extend %atom-vignette-profil;
        margin-top: 40px;

        @include RWD(tablet){
            margin-top: 30px;
        }

        &:nth-child(1), &:nth-child(2){
            @include RWD(desktop){
                margin-top: 0;                
            } 
        }
    }
}
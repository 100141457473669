%bone-form-structure{
    $gutter: 30;

    .form-group{
        display: block;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    // Inputs à la suite
    .webform-layout-box{
        display: flex;

        .form-group + .form-group{
            margin-left: 120px;
        }

        @include RWD(mobile){
            display: block;
            .form-group{
                width: 100%;

                & + .form-group{
                    margin-left: 0;
                }
            }
        }
    }
    
    //  Dates avec datepicker
    .webform-datepicker{
        @extend %molecule-select-group-inline;
        @include RWD(mobile){
            flex-wrap: wrap;

            > .form-group{
                margin-bottom: 10px !important;
            }
        }
    }

    // Type heure
    .type-webform-time{
        .webform-container-inline{
            @extend %molecule-select-group-inline;
        }
    }

    .form-label{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    .form-field{
        // width: calc(83% - #{$gutter / 2}px); 
        // margin-left: #{$gutter / 2}px;
        display: flex;
        flex-direction: column;
        position: relative;

        .field-suffix{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
    }
    
    .form-infos{
        width: 100%;
        // margin-left: calc(17% + #{$gutter / 2}px);
    }

    .form-actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > * {
            margin-left: 20px;
        }

        @include RWD(mobile){
            flex-direction: column;
            align-items: flex-end;

            & > *{
                margin-left: 0;

                & + * {
                    margin-top: 20px
                }
            }
        }
    }


    @include RWD(mobile){
        .form-group{
            display: block;
        }
        .form-label{
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            display: block;
        }
        .form-field{
            width: 100%;
            margin-left: 0;
        }
    }
}
%molecule-ariane{
    display: flex;
    flex-wrap: wrap;

    li, li a{
        font-size: 16px;
        font-size: 1.6rem;
        color: #2d2d2d;
        text-decoration: none;
    }
    li a{
        &:hover, &:focus{
            text-decoration: underline;
        }
    }
    li{
        &:after{
            content: '>';
            margin-left: 3px;
            margin-right: 3px;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
        .page.active{
            opacity: 0.7;
        }
    }

    @include RWD(mobile){
        display: none;
    }
}
%molecule-select-group-inline{
    align-items: center;
    display: flex;

    & > .form-group{
        margin: 0;
        margin-right: 10px;
    }
    .form-label{
        margin: 0;
        display: none;
    }
    .form-field{
        margin: 0; 
    }
    .customSelectContain{
        width: 100px;
    }
    .webform-calendar{
        width: auto; 
        padding: 0;
        height: auto;
        border: none; 
        border-radius: 0;
    }
    .form-item-submitted-heure-minute{
        margin-left: 10px;
    }
}
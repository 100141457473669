%organism-map-list{
    // Yosemite
    .view-filters{
        @extend %molecule-search-filters;
    }
        
    .markerLabel{ 
        color: #FFFFFF; 
    }
    // Map Engine
        // UI
        #views-exposed-form-maps-page{
            @extend %molecule-form;
            @extend %organism-map-ui;
            position: absolute;
            top: 10px;
            left: 10px;
        }
        // InfoBox
        .infoBox{
            width: 260px;
            @extend %molecule-map-infobox;
        }

    // Résultats 
    .view-header{
        margin-bottom: 20px;
    }
    
    // Grille
    .view-grid{
        @extend %molecule-grid-basic;  

        .view-full-contain{
            display: none;
        }
    }

    // View Full
    #view-full{
        display: none;
        .view-full-contain{
            @extend %atom-vignette--map-full;
        }
    }
    
    // Pagination
    .pagination{
        @extend %molecule-pagination;
        margin-bottom: 40px;
    }
}
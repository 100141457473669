%atom-map-infowindow{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    padding-top: 80px;

    @include RWD(mobile){
        padding: 20px;
        padding-top: 40px;
    }

    .infowindow-content{
        .gmap-icon{
            margin-bottom: 25px;
            text-align: center;
            svg{
                height: 35px;
                path{
                    fill: $second-color;
                }
            }
            @include RWD(mobile){
                display: none;
            }
        }
        .gmap-title{
            @extend %atom-h3-rte;
            margin-bottom: 25px;
            padding-left: 0 !important;
        }
        .gmap-loc{
            strong{
                display: block;
            }
        }
        .gmap-description{
            display: none;
        }
    }
    .gmap-close{
        // @extend %icon-close;
        cursor: pointer; 
        position: absolute;
        top: 25px;
        right: 35px;
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, rgba(#2d2d2d,0.5)) );
        opacity: 1;
        transition: all, 0.5s;

        @include RWD(mobile){
            top: 10px;
            right: 10px;
        }

        &:hover{
            opacity: 0.5;
            // &:after, &:before{
            //     background-color: #2D2D2D;
            // }
        }
    }
} 
%organism-form{
    .webform-client-form{
        @extend %container-form;
        // Structure/Distribution
        @extend %bone-form-structure;
        // Design des champs
        @extend %molecule-form;
        // Validation
        @extend %skin-form-validation; 
        clear: both;
        margin-bottom: $margin-item-rte;

        &.preview{
            @extend %molecule-form-preview;
        }
    }

    // Date picker
    #ui-datepicker-div{
        @extend %atom-datepicker;
    }
    // Progress Bar étapes
    .webform-progressbar{
        @extend %molecule-nav-progressbar;
    }
    // Confirmation d'envoi
    .webform-confirmation{
        @extend %molecule-form-confirmation;
    }
    // Messages d'erreurs BO
    .messages.error, .messages.warning{
        @extend %molecule-form-warning;
        margin-bottom: 20px;
    }
    .container{
        .messages.error, .messages.warning{
            @include RWD(desktop){
                margin-left: -120px;
                margin-right: -120px;                    
            }
        }
    }
}
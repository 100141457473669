%atom-customSelect{
    height: 35px;
    .customSelect {
        padding: 7.5px 20px;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        
        &.arrowed {
            &:after {
                @extend %icon-arrow-select;
            }
        }
        option{
            padding: 10px;
        }
    }

    &--default{
        @extend %atom-customSelect;
        .customSelect{
            background-color: #fff;
            border-color: #d0d0d0;
            border-radius: 0;

            &.arrowed:after{
                border-color: #d0d0d0;
            }
        }
        .customSelectInner{
            color: #000000;
        }
        .customSelectContain.focused .customSelect{
            border: solid 1px $core-color;
        }
        .customSelectContain select{
            background-color: #FFFFFF;
            color: #000000;
        }
    }
} 
%molecule-pagination{    
    width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;

	.pagin-item{
		display: inline-block;
		color: inherit;
		margin-left: 15px;
		&:before{
			display: none !important;
		}

		a{
			position: relative; 
			z-index: 2;
		}

		&.pagin-prev{
			margin-right: auto;
		}

		&.disabled{
			pointer-events: none;
		}
		&.is-active{
			pointer-events: none;
		}
		&.pagin-next{
			margin-left: auto;
		} 
		&.pagin-first{
			margin-left: 0;
		} 
		&:not(.is-active):not(.disabled){			
			& > div, & > a{
				@extend %atom-btn-int--core;
				padding: 0 15px;
				text-decoration: none;
			}
		}
		&.is-active, &.disabled{
			& > div, & > a{
				@extend %atom-btn-int--white; 
				padding: 0 15px;
				text-decoration: none;
			}
		} 
	}
	@include RWD(tablet){
		.pagin-first,
		.pagin-last{
			display: none !important;
		}
	}
	@include RWD(mobile){
		.pagin-item:not(.pagin-next):not(.pagin-prev){
			display: none;
		}
		.pagin-next{
			margin-left: auto;
		}
		.pagin-next, .pagin-prev{
			.btn-text{
				font-size: 0 !important;
			}
		}
		.pagin-prev .btn-text:before{
			content:'Prec'; 
			font-size: 16px;
			font-size: 1.6rem;
		}
		.pagin-next .btn-text:before{
			content:'Suiv';
			font-size: 16px;
			font-size: 1.6rem;
		}
	}
}
%organism-event-list{

    // Filters
    .view-filters{
        @extend %molecule-search-filters--agenda;
    }

    // Résultats 
    .view-header{
        margin-bottom: 20px;
    }

    // Date separator
    .view-date-separator{
        @extend %atom-h2-rte;
        margin-bottom: 20px;
    }
    
    // Grille
    .view-grid{
        @extend %molecule-grid-basic;  
    }
    
    // Pagination
    .pagination{
        @extend %molecule-pagination;
        margin-bottom: 40px;
    }
}
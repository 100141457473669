%molecule-link-group{
    clear: both;
    li{
        // margin-bottom: 30px;
        a{
            transition: all, 0.5s;

            &:hover, &:focus{
                background-color: $core-color-light !important;
            }
        }
        &:nth-child(2n+1){
            a{
                background-color: #f7f7f7;
            }
        }
    } 

    .link-group-item{
        display: flex;
        align-items: center;
        @extend %atom-h3-rte;
        padding: 20px;
        text-decoration: none;
        color: #595959;

        &:before{
            content: '';
            display: inline-block;
            width: 30px;
            height: 20px;
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-arrow1, $core-color) );
            margin-right: 20px;
        }
        
        span{
            display: none; 
        }
    }
}
%skin-form-validation{
    .error{
        border-color: $error !important;
    }
    span.error{
        color: $error;
        margin-top: 5px;
    }
    .valid{
        border-color: $success !important;
    }
}
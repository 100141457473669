%organism-rte{
    // Inlines
    @extend %molecule-inlines-rte;

    /* Headings */
    @extend %molecule-headings-rte;

    /* Inlines */
    p{
        margin-bottom: $margin-p;
    }

    /* Images */
    .type-image{
        @extend %molecule-images-rte;        
    }

    /* Listes */
    ul:not(.unstyled){ 
        @extend %atom-ul-rte;
        clear: both;
    }
    ol:not(.unstyled){
        @extend %atom-ol-rte;
        clear: both;
    }

    /* MEDIAS */
    .media{
        @extend %molecule-media;
        clear: both;
        margin-bottom: $margin-item-rte;

        &.media-audio{
            @extend %molecule-media-audio !optional;
        }
        &.media-video{
            @extend %molecule-media-video !optional;
        }
        &.media-download{ 
            @extend %molecule-media-download !optional;
        }
        &.media-publication{
            @extend %molecule-media-publication !optional;
        }
        &.media-gmaps{
            @extend %molecule-media-map;
        }

    }

    // Quote
    .quote{
        clear: both;
        @extend %molecule-quote;
        @extend %bone-last-unmarged;
        margin-bottom: $margin-item-rte;
    }

    // Highlight Content
    .highlight-content{
        @extend %molecule-highlight-content;
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    //  Slider Int
    .slider-int{
        @extend %molecule-slider--cms;
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Trombinoscope
    .grid-trombinoscope{
        @extend %molecule-trombinoscope;
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Table
    .table-content{
        @extend %molecule-table--responsive;
        clear: both;
        margin-bottom: $margin-item-rte;
    }   
    // Groupe de liens
    .link-group{
        @extend %molecule-link-group;
        clear: both;
        margin-bottom: $margin-item-rte;
        width: 600px;
        max-width: 100%;
    }

}
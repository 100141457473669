%molecule-slider--cms{
    position: relative;

    // Activation du mode background-cover
    @extend %bones-slider-cover-ratio--cms; // situé dans le CORE
 
    // Animation arrow prev next
    @include animationArrowSliderMoveIn(-20%, 40px); // situé dans le CORE
    
    // FULL HEIGHT => .owl-item: 100vh + le slider en position absolute, top/bottom/left/right 0 + pager en position absolute;

    // Taille et couleur des slides
    .owl-item{ 
        height: 555px;
        @include RWD(tablet){
            height: 500px;
        }
        @include RWD(mobile){
            height: 250px;
        }
        background-color: #d4d4d4;

        // Slide image
        &:not([data-video]) > li{
            @extend %atom-slide--cms;
        }

        // Slide Video
        .owl-video-tn{
            @extend %atom-slide--cms--video;
        }
    }

    // NEXT/PREV ARROWS
    .owl-nav{ 
        justify-content: center;
        display: flex;
        @extend %bones-slider-Ycentered-control--cms;

        .owl-prev{
            @extend %atom-slider-arrow--cms--grey; // Flèches
            @extend %atom-slider-arrow-paged--cms--grey; // Pagination X/Y
            @extend %animation-slider-arrow-fade--cms; // Apparition fade de la pagination
            transform: translateY(-50%);
            left: 0;
            .owl-prev-icon{
                transform: rotate(-180deg);
            }
        }
        .owl-next{ 
            @extend %atom-slider-arrow--cms--grey; // Flèches
            @extend %atom-slider-arrow-paged--cms--grey; // Pagination X/Y
            @extend %animation-slider-arrow-fade--cms; // Apparition fade de la pagination
            transform: translateY(-50%);
            right: 0;
        } 

        &.disabled{
            display: none;
        }
    }

    // PAGERS
    .owl-dots{
        margin-top: 40px;
        justify-content: center;
        display: flex;
        display: none;

        .owl-dot{
            @extend %atom-sliders-dot--cms;
        }
    }
}
html{
    font-size: 10px;
    font-family: $site-font;

    body{
        overflow-x: hidden;
    }

    &-no-scroll{
        height: 100%;
        overflow: hidden;
    }
}
#main-content{
    overflow: hidden;
}
.container{
    @extend %container;
}
::selection{
    background-color: $core-color;
    color: #FFFFFF;
}
::-moz-selection{
    background-color: $core-color;
    color: #FFFFFF;
}
::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
} 
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
opacity: 1 !important;
}
#loader{
    @extend %molecule-loader;
}
#cookies{
    @extend %molecule-cookies;
}
#debug-master{ 
    @extend %molecule-debug-master;
}
.alert-banner .user-alert{ 
    @extend %molecule-cookies;
    @extend %molecule-user-alert;
}
.element-invisible, .hidden{
    display: none;
}
header{
    @extend %organism-header-home;
}
footer{
    @extend %organism-footer;
}
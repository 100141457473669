%text-h1{
    font-size: 50px;
    font-size: 5.0rem;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    
    @include RWD(mobile){
        font-size: 21px;
        font-size: 2.1rem;
        letter-spacing: 0.15em;
    }
}

%text-h2{
    font-family: $site-font;
    color: #212121;
    font-size: 32px;
    font-size: 3.2rem; 
    letter-spacing: 0.05em;
    font-weight: 600;

    @include RWD(mobile){
        font-size: 22px;
        font-size: 2.2rem;
    }
}
%text-h3{
    font-family: $site-font;    
    font-weight: 500;
    font-size: 20px;
    font-size: 2.0rem;
    color: #212121;
    letter-spacing: 0.05em;
    
    @include RWD(mobile){
        font-size: 17px;
        font-size: 1.7rem;
    }
}
%text-h4{
    font-family: $site-font;    
    font-weight: 400;
    font-size: 16px;
    font-size: 1.6rem;
    color: #212121;
    letter-spacing: 0.05em;

    @include RWD(mobile){
        font-size: 14px;
        font-size: 1.4rem;
    }
}
%text-h5{
    font-size: 14px;
    text-transform: uppercase;
}
%text-hat{
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

//  HP
%text-section-title-hp{
    font-weight: 600;
    font-size: 32px;
    font-size: 3.2rem;
    letter-spacing: 0.05em;
}
%text-section-subtitle-hp{
    font-weight: 400;
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase; 
    letter-spacing: 0.05em;
}

// TEASERS
%text-teaser-title{
    font-weight: 500;
    font-size: 20px;
    font-size: 2.0rem;
}
